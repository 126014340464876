var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container-xl"},[_c('div',{staticClass:"crew-member-expiring-passport-table"},[_c('base-data-table',{attrs:{"title":"Expiring Passports","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"no-create":""},scopedSlots:_vm._u([{key:"item.passportExpiry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.passportExpiry))+" ")]}},{key:"item.dateOfBirth",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateAge")(item.dateOfBirth))+" ")]}},{key:"item.employmentEndDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.employmentEndDate))+" ")]}},{key:"item.currentVesselName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currentVesselName ? item.currentVesselName : "N/A")+" ")]}},{key:"item.currentVesselRss",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currentVesselRss ? item.currentVesselRss : "N/A")+" ")]}},(!_vm.agencyAdminRole)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onUpdateCrewMember(item)}}},[_c('v-list-item-title',[_vm._v("View")])],1)],1)],1)]}}:null],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }













































import { Vue, Component } from "vue-property-decorator";
import Api from "@/api";
import nameof from "@/utility/nameof";
import { getModule } from "vuex-module-decorators";
import { CrewMemberModel } from "@/api/generated";
import SnackbarModule from "@/store/snackbarModule";
import { DataTableHeader } from "vuetify";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class CrewMemberExpiringPassportTable extends Vue {
  private loading = false;
  private items: Array<CrewMemberModel> = [];
  private headers: Array<DataTableHeader<CrewMemberModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<CrewMemberModel>("fullname") },
    {
      text: "Nationality",
      value: nameof<CrewMemberModel>("countryNationality")
    },
    { text: "Age", value: nameof<CrewMemberModel>("dateOfBirth") },
    { text: "Current Vessel", value: nameof<CrewMemberModel>("currentVessel") },
    { text: "Agency", value: nameof<CrewMemberModel>("agencyName") },
    {
      text: "Passport Expiry",
      value: nameof<CrewMemberModel>("passportExpiry")
    },
    {
      text: "Date of Termination",
      value: nameof<CrewMemberModel>("employmentEndDate")
    }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getCrewMembers();
  }

  private async getCrewMembers() {
    try {
      this.loading = true;
      if (userModule.role == "AGM") {
        const response = await Api.CrewMemberService.apiCrewmemberPassportExpiryUserIdGet(
          userModule.userId
        );
        this.items = response.data;
      } else {
        const response = await Api.CrewMemberService.apiCrewmemberPassportExpiryGet();
        this.items = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch crew members");
    } finally {
      this.loading = false;
    }
  }

  private async onUpdateCrewMember(crewMember: CrewMemberModel) {
    if (!crewMember.crewMemberId) {
      return;
    }
    this.$router.push({
      name: "Crew Member Edit",
      params: { crewMemberId: crewMember.crewMemberId }
    });
  }
}

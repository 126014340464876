






import { Vue, Component } from "vue-property-decorator";
import CrewMemberExpiringPassportTable from "@/components/crew-member/CrewMemberExpiringPassportTable.vue";

@Component({ components: { CrewMemberExpiringPassportTable } })
export default class ExpiringPassportOverview extends Vue {}
